import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import AppStore from '../../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import { timeAgo, getTextColor } from '../../Utilities';

function LayoutInfo() {
  const location = useLocation();
  const [layout, setLayout] = useState({});
  const [appStoreLink, setAppStoreLink] = useState({});

  useEffect(() => {
    const segments = location.pathname.split('/');
    const last = segments.pop() || segments.pop(); // Handle potential trailing slash
    // const baseUrl = "http://localhost:3000"; // Local
    // const baseUrl = "htp://192.168.1.206:3000"; // Accessing via device
    const baseUrl = "https://test.curatelife.app"; // Test
    // const baseUrl = "https://dev.curatelife.app"; // Dev

    const exploreLayoutUrl = `${baseUrl}/api/app/layouts/${last}`;

    axios.get(exploreLayoutUrl).then((res) => {
      const item = res.data;
      const date = new Date(item.createdAt);
      const result = {
        id: item.id,
        name: item.name,
        preview: item.preview,
        username: item.user.username,
        displayName: item.user.displayName,
        profilePhoto: item.user.profilePhoto,
        createdAt: `Created ${timeAgo(date)}`,
        backgroundColor: item.backgroundColor, // Assuming `backgroundColor` is part of the layout
        textColor: getTextColor(item.backgroundColor)
      };
      setLayout(result);

      const oneLinkURL = "https://curatelife.onelink.me/3yMD";

      const mediaSource = {keys: ["inmedia"], defaultValue: "web"};
      const deepLinkValue = { defaultValue: `layout/${item.id}/${item.user.id}` };

      if (window.AF_SMART_SCRIPT) {
        const link = window.AF_SMART_SCRIPT.generateOneLinkURL({
          oneLinkURL,
          afParameters: {
            mediaSource: mediaSource,
            deepLinkValue: deepLinkValue
          }
        });
        setAppStoreLink(link);
      } else {
        console.error('AF_SMART_SCRIPT is not loaded');
      }
    });
  }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen items-center">
      <div className="bg-lightBlue bg-opacity-30 rounded-full flex flex-col w-full max-w-screen-md p-4 mb-4 text-center px-10">
        <div className="flex items-center justify-center">
          <span className="text-2xl w-full font-backGroove text-lightBlue text-left">
            Join The Movement!
          </span>
          <a href={appStoreLink.clickURL} target="_blank" rel="noreferrer">
            <img className="h-10" src={AppStore} alt="Download on the App Store" />
          </a>
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center p-4 space-y-5 w-full max-w-screen-md">
        <div className="bg-white rounded-xl flex flex-col w-full">
          <div className="p-4">
            <h1 className="text-4xl font-backGroove text-gray-800 text-center">{layout.name}</h1>
            <p className="mt-1 mb-2 font-polliSansBold text-gray-500 text-center">{layout.createdAt}</p>
          </div>
          <div className="relative border-y-2 border-gray-300 flex items-center justify-center"
          style={{ minHeight: '400px', maxHeight: '600px' }}>
            {layout.preview ? (
              <img
                className="object-contain"
                src={layout.preview}
                alt="Layout preview"
                style={{
                  backgroundColor: `#${layout.backgroundColor}` || '#f0f0f0',
                  minHeight: '400px',
                  maxHeight: '600px',
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : (
              <div
                className="flex items-center justify-center w-full h-full bg-gray-300"
                style={{
                  backgroundColor: `#${layout.backgroundColor}` || '#f0f0f0',
                  minHeight: '400px',
                  maxHeight: '600px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <p className="text-center"
                style={{ color: `#${layout.textColor}` }}>
                  No preview available
                </p>
              </div>
            )}
          </div>
          <div className="flex p-4 justify-between items-center">
            <div className="flex items-center space-x-2">
              <img className="w-10 h-10 rounded-full bg-gray-200" src={layout.profilePhoto} alt="profile photo" />
              <div>
                <h2 className="text-gray-800 font-polliSansBold text-left">{layout.displayName}</h2>
                <h3 className="text-gray-500 font-polliSansBold text-left">{layout.username}</h3>
              </div>
            </div>
            <div className="flex space-x-2">
              {/* Additional content can go here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutInfo;
