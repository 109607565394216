export function timeAgo(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
  
    const interval = Math.floor(seconds / 31536000);
    if (interval >= 1) return interval + (interval === 1 ? " year ago" : " years ago");
  
    const months = Math.floor(seconds / 2628000);
    if (months >= 1) return months + (months === 1 ? " month ago" : " months ago");
  
    const days = Math.floor(seconds / 86400);
    if (days >= 1) return days + (days === 1 ? " day ago" : " days ago");
  
    const hours = Math.floor(seconds / 3600);
    if (hours >= 1) return hours + (hours === 1 ? " hour ago" : " hours ago");
  
    const minutes = Math.floor(seconds / 60);
    if (minutes >= 1) return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
  
    return "just now";
  }
  
  export function getTextColor(backgroundColor) {
    if (!backgroundColor) return '000000';
    const r = parseInt(backgroundColor.substr(0, 2), 16);
    const g = parseInt(backgroundColor.substr(2, 2), 16);
    const b = parseInt(backgroundColor.substr(4, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    return brightness >= 128 ? '000000' : 'ffffff';
  }