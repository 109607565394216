import React from 'react';

function LayoutCard({ layout }) {
  return (
    <div>
      <div className='relative rounded-lg cursor-pointer'>
        {/* Container with fixed aspect ratio and background color */}
        <div className='rounded-lg bg-gray-200'>
          {layout.preview ?
          <img 
            src={layout.preview} 
            className='w-full h-auto rounded-lg' 
            alt='layout preview' 
            style={{ minHeight: '400px', maxHeight: '400px' }} // Set a minimum height
          />
            :
            (
            <div
              className="flex items-center justify-center rounded-lg"
              style={{
                minHeight: '400px', 
                maxHeight: '400px',
                backgroundColor: `#${layout.backgroundColor}` || '#f0f0f0', // Default background color
              }}
              >
                <p className="text-center"
                style={{ color: `#${layout.textColor}` }}>No preview available</p>
              </div>
            )
          }
        </div>

        <div className="w-full absolute bottom-0 z-10 px-2 py-1 bg-white rounded-b-lg">
          <h2 className='text-left font-polliSansBold md:text-xl sm:text-md'>{layout.name}</h2>
          <div className='flex flex-row gap-1'>
            {/* Profile photo with background color and fixed size */}
            <div className='w-6 h-6 bg-gray-200 rounded-full'>
              <img 
                src={layout.profilePhoto} 
                className='w-full h-full rounded-full object-cover' 
                alt='profile photo' 
              />
            </div>
            <p className="text-left font-polliSansRegular md:text-xl sm:text-md">{layout.username}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutCard;
