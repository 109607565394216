import React from 'react'
import LayoutInfo from '../Components/LayoutDetails/LayoutInfo'

function LayoutDetails() {
  return (
    <div>
      <LayoutInfo />
    </div>
  )
}

export default LayoutDetails