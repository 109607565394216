import React from 'react'
import LayoutsGrid from '../Components/Explore/LayoutsGrid'
import Main from '../Components/Explore/Main'

function Explore() {
  return (
    <div>
      <Main />
      <LayoutsGrid />
    </div>
  )
}

export default Explore