import './App.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Explore from './Pages/Explore';
import LayoutDetails from './Pages/LayoutDetails';
import { Routes, Route, Router } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Nav />
      <div className='p-5'>
        <Routes>
          <Route path='/' element={<Explore />}></Route>
          <Route path='/layout/:id' element={<LayoutDetails />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
