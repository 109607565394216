import React from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';

function Nav() {

  const navigate = useNavigate()

  return (
    <div>
        <div className='flex justify-center items-center' onClick={()=>navigate('/')}>
            <img src={require('../Curate Icon.png')} className='w-[50px] md:w-[75px] lg:w-[100px] p-1 md:p-2 lg:p-4 cursor-pointer' alt="logo" />
            {/* <h1 className='font-backGroove text-3xl md:text-4xl lg:text-5xl'>Curate</h1> */}
        </div>
    </div>
  )
}

export default Nav